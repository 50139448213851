import actions from './actions';
import toasterActions from 'components/toaster/container/actions';
import {
  checkUserRegistered,
  loginWithPassword,
  registerUser,
  confirmUser,
  registerGuest,
  forgetPassword,
  changePasswordReq,
  getUserProfile,
  getNotifications,
} from './api';
import axios from 'axios';

const loginPerformances = (dispatch) => {
  const setLoginState = (value) => {
    dispatch(actions.setLoginState(value));
  };
  const setLoginType = (value) => {
    dispatch(actions.setLoginType(value));
  };
  const setAdminType = (value) => {
    dispatch(actions.setAdminType(value));
  };
  const setAdminPhone = (value) => {
    dispatch(actions.setAdminPhone(value));
  };
  const setLanguage = (value) => {
    dispatch(actions.setLanguage(value));
  };
  const setStoreLightTheme = (value) => {
    dispatch(actions.setStoreLightTheme(value));
  };
  const setTimeZone = (value) => {
    dispatch(actions.setTimeZone(value));
  };
  const setCurrency = (value) => {
    dispatch(actions.setCurrency(value));
  };
  const setCurrencyToggleRightPos = (value) => {
    dispatch(actions.setCurrencyToggleRightPos(value));
  };
  const setCeiling = (value) => {
    dispatch(actions.setCeiling(value));
  };
  const setDefaultCountryCheckoutId = (value) => {
    dispatch(actions.setDefaultCountryCheckoutId(value));
  };
  const setLogoPicture = (value) => {
    dispatch(actions.setLogoPicture(value));
  };
  const setRedirectContext = (value) => {
    dispatch(actions.setRedirect(value));
  };
  const setLoadingAnonymous = (value) => {
    dispatch(actions.setLoadingAnonymous(value));
  };
  const setIntegrations = (value) => {
    dispatch(actions.setIntegrations(value));
  };
  const checkUserRegisteredContext = async (cellphone) => {
    try {
      const result = await checkUserRegistered(cellphone);
      if (result) {
        dispatch(actions.setLoginState(2));
      } else {
        dispatch(actions.setLoginState(3));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const loginWithPasswordContext = async (data) => {
    try {
      const result = await loginWithPassword(data);
      if (result) {
        localStorage.setItem('layers-token', result.refresh_token);
        dispatch(actions.setProfile(result));
        dispatch(actions.setToken(result.refresh_token));
        axios.defaults.headers.common.Authorization = 'Bearer' + ' ' + result.refresh_token;
      }
    } catch (e) {
      //TODOTRANS
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Wrong password' }));
    }
  };
  const setTokenContext = async (value) => {
    dispatch(actions.setLoginState(value));
  };
  const setPrifleContext = async (value) => {
    dispatch(actions.setProfile(value));
  };
  const setSocketContext = async (value) => {
    dispatch(actions.setSocket(value));
  };
  const setNotificationsContext = async (value) => {
    dispatch(actions.setNotifications(value));
  };
  const newNotificationReceived = async (value) => {
    dispatch(actions.newNotificationReceived(value));
  };
  const getNotificationsContext = async () => {
    try {
      const result = await getNotifications();
      if (result) {
        dispatch(actions.setNotifications(result.notifications));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const registerContext = async (data) => {
    try {
      const result = await registerUser(data);
      if (result) {
        dispatch(actions.setEmail(data.cellphone));
        dispatch(actions.setRedirect('/otp'));
        //TODOTRANS
        dispatch(toasterActions.updateToaster({ type: 'info', message: 'verification code send' }));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const confirmUserContext = async (data) => {
    try {
      const result = await confirmUser(data);
      if (result) {
        localStorage.setItem('layers-token', result.access_token);
        dispatch(actions.setProfile(result));
        // dispatch(actions.setProfile(resultProfile))
        dispatch(actions.setToken(result.access_token));
        axios.defaults.headers.common.Authorization = 'Bearer' + ' ' + result.access_token;
        const resultProfile = await getUserProfile();
        dispatch(actions.setProfile(resultProfile));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const registerAnonymousContext = async ({ family }) => {
    try {
      const result = await registerGuest({ family });
      if (result) {
        dispatch(actions.setEmail(result.cellphone));
        localStorage.setItem('layers-token', result.access_token);
        dispatch(actions.setProfile(result));
        dispatch(actions.setToken(result.access_token));
        axios.defaults.headers.common.Authorization = 'Bearer' + ' ' + result.access_token;
      }
    } catch (e) {
      console.error(e);
    }
  };
  const forgetPasswordContext = async (data) => {
    try {
      const result = await forgetPassword(data);
      if (result) {
        dispatch(actions.setEmail(data));
        dispatch(actions.setRedirect('/otp'));
        //TODOTRANS
        dispatch(toasterActions.updateToaster({ type: 'info', message: 'verification code send' }));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const changePasswordContext = async (data) => {
    try {
      const result = await changePasswordReq(data);
      //TODOTRANS
      dispatch(toasterActions.updateToaster({ type: 'info', message: 'Password changed' }));
    } catch (e) {
      //TODOTRANS
      dispatch(toasterActions.updateToaster({ type: 'error', message: 'Wrong code' }));
      throw e;
    }
  };

  const getUserProfileContext = async () => {
    try {
      const result = await getUserProfile();
      if (result) {
        dispatch(actions.setProfile(result));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    setLoginState,
    setLoginType,
    setAdminType,
    setAdminPhone,
    setLanguage,
    setStoreLightTheme,
    setTimeZone,
    setCurrency,
    setCurrencyToggleRightPos,
    setCeiling,
    setDefaultCountryCheckoutId,
    setLogoPicture,
    setRedirectContext,
    setLoadingAnonymous,
    checkUserRegisteredContext,
    loginWithPasswordContext,
    setTokenContext,
    setSocketContext,
    setNotificationsContext,
    getNotificationsContext,
    newNotificationReceived,
    registerContext,
    confirmUserContext,
    registerAnonymousContext,
    forgetPasswordContext,
    changePasswordContext,
    getUserProfileContext,
    setPrifleContext,
    setIntegrations
  };
};

export default loginPerformances;
