import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import MainContext from 'MainContext';
import axios from 'axios';
// Create Context Object
export const MarketingContext = createContext();
const instrument=(x)=>x;
// Create a provider for components to consume and subscribe to changes
export const MarketingContextProvider = (props) => {
  const { profile } = useContext(MainContext).login;
  const { integrations } = useContext(MainContext).login;


  const [isInitialized, setIsInitialized] = useState({
    platformClarity: false,  // SaaS owner tracking
    shopGA4: false,         // Shop owner tracking
    shopClarity: false      // Shop owner tracking
  });
  
  const [trackingConfig, setTrackingConfig] = useState({
    platformClarityId: 'lnf0am3awd', // Default platform-level Clarity ID
    shopGA4MeasurementId: null,      // Shop-specific GA4
    shopClarityId: null              // Shop-specific Clarity
  });

  const utils = {
    loadScript: useCallback((url) => {
      instrument({ type: 'LOAD_SCRIPT_START', url });
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = url;
        script.onload = () => {
          instrument({ type: 'LOAD_SCRIPT_SUCCESS', url });
          resolve();
        };
        script.onerror = (error) => {
          instrument({ type: 'LOAD_SCRIPT_ERROR', url, error: error.message });
          reject(error);
        };
        document.head.appendChild(script);
      });
    }, [])
  };

  const platformTracking = {
    installClarity: useCallback((clarityId) => {
      instrument({ type: 'INSTALL_PLATFORM_CLARITY_START', clarityId });
      if (!clarityId) {
        instrument({ type: 'INSTALL_PLATFORM_CLARITY_SKIP', reason: 'No clarityId provided' });
        return;
      }
      try {
        const clarityScript = document.createElement('script');
        clarityScript.type = 'text/javascript';
        clarityScript.innerHTML = `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${clarityId}");
        `;
        document.head.appendChild(clarityScript);
        instrument({ type: 'INSTALL_PLATFORM_CLARITY_SUCCESS', clarityId });
        return true;
      } catch (error) {
        instrument({ type: 'INSTALL_PLATFORM_CLARITY_ERROR', clarityId, error: error.message });
        return false;
      }
    }, []),

    initialize: useCallback((clarityId) => {
      instrument({ type: 'INITIALIZE_PLATFORM_CLARITY_START', clarityId, isInitialized });
      if (!clarityId || isInitialized.platformClarity || !profile) {
        instrument({ 
          type: 'INITIALIZE_PLATFORM_CLARITY_SKIP', 
          reason: !clarityId ? 'No clarityId' : isInitialized.platformClarity ? 'Already initialized' : 'No profile',
          clarityId,
          isInitialized,
          hasProfile: !!profile
        });
        return;
      }

      try {
        const scriptInstalled = platformTracking.installClarity(clarityId);
        if (!scriptInstalled) {
          instrument({ type: 'INITIALIZE_PLATFORM_CLARITY_FAIL', reason: 'Script installation failed' });
          return;
        }

        const setClarityUserData = async () => {
          const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
          instrument({ type: 'SET_CLARITY_USER_DATA_START' });
          
          while (typeof window.clarity !== 'function') {
            instrument({ type: 'WAITING_FOR_CLARITY_FUNCTION' });
            await sleep(1000);
          }
          
          window.clarity('set', 'userId', `${profile.family}:${profile.cellphone}:${profile._id}`);
          window.clarity('set', 'userIdShort', profile._id);
          window.clarity('set', 'side', 'customer');
          instrument({ type: 'SET_CLARITY_USER_DATA_SUCCESS', userId: profile._id });
        };

        setClarityUserData();
        setIsInitialized(prev => {
          instrument({ type: 'SET_PLATFORM_CLARITY_INITIALIZED', prev, new: { ...prev, platformClarity: true } });
          return { ...prev, platformClarity: true };
        });
      } catch (error) {
        instrument({ type: 'INITIALIZE_PLATFORM_CLARITY_ERROR', error: error.message });
      }
    }, [isInitialized.platformClarity, profile])
  };

  const shopTracking = {
    ga4: {
      install: useCallback(async (measurementId) => {
        instrument({ type: 'INSTALL_GA4_START', measurementId });
        if (!measurementId) {
          instrument({ type: 'INSTALL_GA4_SKIP', reason: 'No measurementId provided' });
          return;
        }
        try {
          await utils.loadScript(`https://www.googletagmanager.com/gtag/js?id=${measurementId}`);
          
          window.dataLayer = window.dataLayer || [];
          window.gtag = function() {
            window.dataLayer.push(arguments);
          };
          window.gtag('js', new Date());
          window.gtag('config', measurementId);
          
          instrument({ type: 'INSTALL_GA4_SUCCESS', measurementId });
          return true;
        } catch (error) {
          instrument({ type: 'INSTALL_GA4_ERROR', measurementId, error: error.message });
          return false;
        }
      }, [utils.loadScript]),

      initialize: useCallback(async (measurementId) => {
        instrument({ type: 'INITIALIZE_GA4_START', measurementId, isInitialized });
        if (!measurementId || isInitialized.shopGA4 || !profile) {
          instrument({ 
            type: 'INITIALIZE_GA4_SKIP', 
            reason: !measurementId ? 'No measurementId' : isInitialized.shopGA4 ? 'Already initialized' : 'No profile',
            measurementId,
            isInitialized,
            hasProfile: !!profile
          });
          return;
        }

        try {
          const scriptInstalled = await shopTracking.ga4.install(measurementId);
          if (!scriptInstalled) {
            instrument({ type: 'INITIALIZE_GA4_FAIL', reason: 'Script installation failed' });
            return;
          }

          window.gtag('set', {
            'user_id': profile._id,
            'user_properties': {
              'family': profile.family,
              'phone': profile.cellphone
            }
          });

          setIsInitialized(prev => {
            instrument({ type: 'SET_GA4_INITIALIZED', prev, new: { ...prev, shopGA4: true } });
            return { ...prev, shopGA4: true };
          });
          instrument({ type: 'INITIALIZE_GA4_SUCCESS', measurementId });
        } catch (error) {
          instrument({ type: 'INITIALIZE_GA4_ERROR', error: error.message });
        }
      }, [isInitialized.shopGA4, profile])
    },

    clarity: {
      install: useCallback((clarityId) => {
        if (!clarityId) return;
        try {
          const clarityScript = document.createElement('script');
          clarityScript.type = 'text/javascript';
          clarityScript.innerHTML = `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${clarityId}");
          `;
          document.head.appendChild(clarityScript);
          console.log('Clarity script installed');
          return true;
        } catch (error) {
          console.log('Error installing Clarity script: ' + error.message);
          return false;
        }
      }, []),

      initialize: useCallback((clarityId) => {
        if (!clarityId || isInitialized.shopClarity || !profile) return;

        try {
          const scriptInstalled = shopTracking.clarity.install(clarityId);
          if (!scriptInstalled) return;

          const setClarityUserData = async () => {
            const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
            
            while (typeof window.clarity !== 'function') {
              await sleep(1000);
            }
            
            window.clarity('set', 'userId', `${profile.family}:${profile.cellphone}:${profile._id}`);
            window.clarity('set', 'userIdShort', profile._id);
            window.clarity('set', 'userCellphone', profile.cellphone);
            window.clarity('set', 'side', 'customer');
            if (profile.family) {
              window.clarity('set', 'family', profile.family);
            }
          };

          setClarityUserData();
          setIsInitialized(prev => ({ ...prev, shopClarity: true }));
          console.log('Clarity tracking initialized with user data');
        } catch (error) {
          console.log('Error initializing Clarity: ' + error.message);
        }
      }, [isInitialized.shopClarity, profile])
    }
  };


  const events = {
    track: useCallback((eventName, eventData = {}) => {
      instrument({ type: 'TRACK_EVENT_START', eventName, eventData, isInitialized });
      try {
        if (isInitialized.shopGA4 && window.gtag) {
          window.gtag('event', eventName, eventData);
          instrument({ type: 'TRACK_EVENT_SUCCESS', eventName });
        } else {
          instrument({ 
            type: 'TRACK_EVENT_SKIP', 
            reason: !isInitialized.shopGA4 ? 'GA4 not initialized' : 'gtag not available',
            eventName 
          });
        }
      } catch (error) {
        instrument({ type: 'TRACK_EVENT_ERROR', eventName, error: error.message });
      }
    }, [isInitialized.shopGA4]),

    

    trackPurchaseSuccess: (data) => {
      instrument({ type: 'TRACK_PURCHASE_SUCCESS_START', data });
      debugger;
      events.track('purchase', data);
    },
    trackPurchaseFail: (data) => {
      instrument({ type: 'TRACK_PURCHASE_FAIL_START', data });
      events.track('purchase_fail', data);
    },
    trackAddToCart: (data) => {
      instrument({ type: 'TRACK_ADD_TO_CART_START', data });
      events.track('add_to_cart', data);
    },
    trackFileUpload: (data) => {
      instrument({ type: 'TRACK_FILE_UPLOAD_START', data });
      events.track('file_upload', data);
    }
  };

  // Initialize all tracking systems
  useEffect(() => {
    instrument({ type: 'INIT_PLATFORM_TRACKING_START', platformClarityId: trackingConfig.platformClarityId });
    platformTracking.initialize(trackingConfig.platformClarityId);
  }, []);

  // Update the tracking config when integrations changes
  useEffect(() => {
    instrument({ type: 'INTEGRATIONS_UPDATED', integrations });
    if (integrations?.public?.marketing) {
      const newConfig = {
        shopGA4MeasurementId: integrations.public.marketing.ga4,
        shopClarityId: integrations.public.marketing.clarity
      };
      instrument({ type: 'UPDATING_TRACKING_CONFIG', current: trackingConfig, new: newConfig });
      setTrackingConfig(prevConfig => ({
        ...prevConfig,
        ...newConfig
      }));
    }
  }, [integrations]);

  // Initialize shop-level GA4 when measurementId is available
  useEffect(() => {
    instrument({ 
      type: 'GA4_INITIALIZATION_CHECK', 
      measurementId: trackingConfig.shopGA4MeasurementId,
      isInitialized 
    });
    if (trackingConfig.shopGA4MeasurementId && !isInitialized.shopGA4) {
      shopTracking.ga4.install(trackingConfig.shopGA4MeasurementId);
      setIsInitialized(prev => {
        instrument({ type: 'SET_GA4_INITIALIZED', prev, new: { ...prev, shopGA4: true } });
        return { ...prev, shopGA4: true };
      });
    }
  }, [trackingConfig.shopGA4MeasurementId, isInitialized.shopGA4]);

  // Initialize shop-level Clarity when clarityId is available
  useEffect(() => {
    instrument({ 
      type: 'CLARITY_INITIALIZATION_CHECK', 
      clarityId: trackingConfig.shopClarityId,
      isInitialized,
      hasProfile: !!profile?._id
    });
    if (trackingConfig.shopClarityId && !isInitialized.shopClarity && profile?._id) {
      shopTracking.clarity.install(trackingConfig.shopClarityId);
    }
  }, [trackingConfig.shopClarityId, isInitialized.shopClarity, profile]);

  return (
    <MarketingContext.Provider
      value={{
        isInitialized,
        trackEvent: events.track,
        ...events
      }}
    >
      {props.children}
    </MarketingContext.Provider>
  );
};

// Custom hook for easy use of the marketing context
export const useMarketing = () => useContext(MarketingContext); 