import { types } from './actions';
import { sleep } from 'utils';
const reducer = (state, action) => {
  switch (action.type) {
    case types.LOGIN_STATE:
      return { ...state, loginState: action.payload };
    case types.SET_LOGIN_TYPE:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          loginType: action.payload 
        }
      };
    case types.SET_ADMIN_TYPE:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          adminType: action.payload 
        }
      };
    case types.SET_ADMIN_PHONE:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          adminPhone: action.payload 
        }
      };
    case types.SET_LANGUAGE:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          language: action.payload 
        }
      };
    case types.SET_STORE_LIGHT_THEME:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          storeLightTheme: action.payload 
        }
      };
    case types.SET_TIME_ZONE:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          timeZone: action.payload 
        }
      };
    case types.SET_CURRENCY:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          currency: action.payload 
        }
      };
    case types.SET_CURRENCY_TOGGLE_RIGHT_POS:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          currencyToggleRightPos: action.payload 
        }
      };
    case types.SET_CEILING:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          ceiling: action.payload 
        }
      };
    case types.SET_DEFAULT_COUNTRY_CHECKOUT_ID:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          defaultCountryCheckoutId: action.payload 
        }
      };
    case types.SET_LOGO_PICTURE:
      return { 
        ...state, 
        shop_profile: {
          ...state.shop_profile,
          logoPicture: action.payload 
        }
      };
    case types.SET_LOADING_ANONYMOUS:
      return { ...state, loadingAnonymous: action.payload };
    case types.SET_PROFILE:
      return { ...state, profile: action.payload };
    case types.SET_TOKEN:
      return { ...state, token: action.payload };
    case types.SET_SOCKET:
      return { ...state, socket: action.payload };
    case types.SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case types.NEW_NOTIFICATION_RECIEVED:
        let newNotifications=[...state.notifications,action.payload];
        return { ...state , notifications:newNotifications};
    case types.SET_EMAIL:
      return { ...state, email: action.payload };
    case types.SET_REDIRECT:
      return { ...state, redirect: action.payload };
    case types.SET_INTEGRATIONS:
      return { ...state, integrations: action.payload };
    default:
      return state;
  }
};

export const key = 'login';
export default reducer;
