import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BackgroundContext } from 'context/backgroundContext';
import { ThemeContext } from 'context/themeContext';
import MainContext from 'MainContext';
import axios from 'axios';
import * as API from 'containers/login/container/api';
import LoadingBlueSVG from 'asset/loading-blue.svg';
import { languages } from 'utils/languages';
import { useTranslation } from 'react-i18next';
import { sleep,isInIFrame } from 'utils';
const LoadingBlueIcon = styled.img.attrs({
  src: LoadingBlueSVG,
})`
  width: 10vw;
  height: 10vw;
`;

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: right 0.3s;
  background: ${(props) => (props.theme&&!isInIFrame() ? props.theme.primaryBackground : 'none')};
  font-family: ${(props) => (props.language === 1 ? 'FFont' : 'Roboto')};
  input {
    font-family: ${(props) => (props.language === 1 ? 'FFont' : 'Roboto')};
  }
`;

const Content = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(100vh - 4.008vw);
  top: 4.008vw;
  left: 0;
  box-sizing: border-box;
`;

const LeftWrapper = styled.div`
  position: absolute;
  width: ${(props) => (props.leftWidth ? 2 * props.leftWidth + 'vw' : '0')};
  height: 500vh;
  top: -185vh;
  left: ${(props) =>
    props.leftWidth ? (props.rtlDirection ? 'unset' : -props.leftWidth + 'vw') : '0'};
  right: ${(props) =>
    props.leftWidth ? (props.rtlDirection ? -props.leftWidth + 'vw' : 'unset') : 'unset'};
  transition: right 0.3s;
  background: ${(props) => (props.theme&&!isInIFrame() ? props.theme.SecondaryBackground : 'none')};
  transform: ${(props) =>
    props.rotateAngle ? 'rotate(' + props.rotateAngle + 'deg)' : 'rotate(0deg)'};
  transition: left 0.4s ease-in-out, width 0.4s ease-in-out, height 0.4s ease-in-out,
    transform 0.4s ease-in-out;
`;

const LoadingWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${(props) => (props.theme ? props.theme.primaryBackground : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900000000000;
`;

function getQueryStringParams(params) {
  // first decode URL to get readable data
  var href = decodeURIComponent(window.location.href);
  // regular expression to get value
  var regEx = new RegExp('[?&]' + params + '=([^&#]*)', 'i');
  var value = regEx.exec(href);
  // return the value if exist
  return value ? value[1] : null;
}

function Background(props) {
  const { leftWidth, setLeftWidth, rotateAngle, setRotateAngle } = useContext(BackgroundContext);
  const {
    registerAnonymousContext,
    loginType,
    setLoginType,
    setAdminType,
    setAdminPhone,
    language,
    setLanguage,
    setStoreLightTheme,
    setTimeZone,
    setCurrency,
    setCurrencyToggleRightPos,
    setCeiling,
    setDefaultCountryCheckoutId,
    setLoadingAnonymous,
    loadingAnonymous,
    setIntegrations,
  } = useContext(MainContext).login;
  const { theme, setLogoPicture, setDarkThemeSelected, setRtlDirection, rtlDirection } =
    useContext(ThemeContext);
  const [family, setFamily] = useState('');
  const { i18n } = useTranslation();
  const [languageSelected, setLanguageSelected] = useState(false); // Added to show the loading until the language selection is complete.
  let familyHelper = getQueryStringParams('family');
  
  

  const getFamilyfromSubDomain = async () => {
    let host = decodeURIComponent(window.location.host);
    let value;
    if (host.split('.')[1] === '3dlayers') {
      value = host.split('.')[0];
    } else {
      value = host;
    }
    if (value.includes('localhost')) value = 'hashtablaku'; //for test in localhost
    document.title = value;
    if(value=='directe-offerte.rezscale.com'){
      document.title = 'Rezscale | Directe Offerte';
      let iconAddress = `/RezScaleFav.png`;
      document.querySelectorAll('link[rel="apple-touch-icon"], link[rel="icon"], link[rel="manifest"]').forEach(el => el.remove());
      // <link rel="manifest" href="/RezScaleManfest.json" />
      let iconTags=`
      <link rel="icon" href="${iconAddress}" type="image/png">
      <link rel="icon" href="${iconAddress}" sizes="32x32">
      <link rel="icon" href="${iconAddress}" sizes="192x192">
      <link rel="apple-touch-icon" href="${iconAddress}">
      <meta name="msapplication-TileImage" content="${iconAddress}">
      `;
      document.head.insertAdjacentHTML('beforeend', iconTags);
    }
    const result = await API.getFamilyBySubdomain(value);

    // Extract integrations if available in the result
    if (result.integrations) {
      setIntegrations(result.integrations);
    }

    setFamily(result.family);
    setLoginType(result.login_type);
    setAdminType(result.admin_type);
    setAdminPhone(result.admin_phone);
    setLanguage(result.language);

    setStoreLightTheme(result.store_light_theme);
    setRtlDirection(languages[result.language].rtl);
    i18n.changeLanguage(languages[result.language].value);
    setTimeZone(result.time_zone);
    setCurrency(result.currency_data);
    setCurrencyToggleRightPos(result.currency_right_pos);
    setCeiling(result.ceiling);
    setDefaultCountryCheckoutId(result.defualt_checkout_country);
    const token = localStorage.getItem('layers-token');
    if (!token) {
      setDarkThemeSelected(!result.store_light_theme);
    }
    if (!token && result.login_type > 1) {
      setLoadingAnonymous(true);
      registerAnonymousContext({ family: result.family });
    }
  };

  useEffect(() => {
    if (familyHelper) {
      setFamily(familyHelper);
    } else {
      getFamilyfromSubDomain();
    }
  }, []);

  useEffect(() => {
    axios.defaults.headers.common.family = family;
  }, [family]);

  const setLogo = async () => {
    const result = await API.getFamilyLogo();
    setLogoPicture(result.logo_picture);
  };

  useEffect(() => {
    if (axios.defaults.headers.common.family) {
      setLogo();
      setLanguageSelected(true); // Added to show the loading until the language selection is complete.
    }
  }, [axios.defaults.headers.common.family]);

  return (
    <Wrapper theme={theme} language={language}>
      <LeftWrapper
        rtlDirection={rtlDirection}
        leftWidth={leftWidth}
        rotateAngle={rotateAngle}
        theme={theme}
      />

      {!languageSelected ? ( // 'loadingAnonymous' is changed to '!languageSelected' to show the loading until the language selection is complete.
        <></>
      ) : (
        // <LoadingWrapper theme={theme}>
        //   <LoadingBlueIcon theme={theme} />
        // </LoadingWrapper>
        <Content>{props.children}</Content>
      )}
    </Wrapper>
  );
}

export default React.memo(Background);
